import React, { FC } from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/doc-layout";


interface StoryProps {
  className?:string;
  location?:Location;
  data: {
    markdownRemark: {
      timeToRead: string;
      html:string;
    frontmatter:{ title: string; date: string;}
    }
  }
}

const Story : FC<StoryProps> = ({ className, location, data: { markdownRemark } }) => {
  const post = markdownRemark;
  return (
    <Layout location={location}>
      <div className={className}>
        <div className="header">
          <h1>{post.frontmatter.title}</h1>
          <p className="description">Written By Sophia Wood &middot; {post.timeToRead} min read</p>
        </div>
        {/* <hr style={{ backgroundColor: "#333" }} /> */}
        <div className="scripty" dangerouslySetInnerHTML={{ __html: post.html }} />
        {/* <hr style={{ backgroundColor: "#333" }} /> */}
        <div style={{ color: "#bbb", display: "block" }}>(C) Sophia Wood &middot; {post.frontmatter.date}</div>
      </div>
    </Layout>
  );
};

const StyledStory = styled(Story)`
  -webkit-touch-callout: none;
  user-select: none;
  width: 100%;
  color: #222;
  font: Courier, "Courier New", monospace;
  letter-spacing: 0 !important;
  /* font-family: "Courier Final Draft", "Courier New", Courier, monospace, Courier New, monospace; */
  line-height: 107.5%;
  margin-bottom: 25px;
  text-align: left;
  z-index: 100;
  font-size: 12pt;

  & header li,
  & header p,
  & header ul {
    display: inline;
    float: left;
    margin: 0;
    padding: 0;
  }

  & header {
    height: 250pt;
    color: #333;
    padding-bottom: 20pt;
    display: block;
  }

  & header p {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-top: 10px;
    text-align: center;
    width: 44%;
  }

  & header ul {
    padding: 0;
    width: 27%;
    -webkit-touch-callout: none;
    user-select: none;
  }

  & .scripty,
  & .header {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 640pt;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 20pt;
    padding-top: 20pt;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10pt;
    line-height: 2;
  }

  & .scripty > p {
    line-height: 1.6;
  }

  & .description {
    color: #bbb;
    display: block;
  }
`;

export default StyledStory;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fileAbsolutePath: { eq: $slug }) {
      html
      timeToRead
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
