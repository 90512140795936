import React, { FC } from "react";
import { Link } from "gatsby";
import GlobalStyle from "../styles/global";
import SEO from "./SEO";
import styled from "styled-components";

const Layout : FC<{className?:string,location?:Location}> = ({ className, children }) => (
  <div className={className}>
    <SEO />
    <GlobalStyle />
    <div className="toolbar">
      <Link to="/">Home</Link>
    </div>
    <div className="content">{children}</div>
  </div>
);

export default styled(Layout)`
  background-color: #aaa;

  & .toolbar {
    display: block;
    position: fixed;
    overflow: hidden;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 100;
  }

  & .toolbar a {
    float: right;
    display: block;
    color: blue;
    text-align: center;
    padding: 11px;
    text-decoration: none;
  }

  & .toolbar + & .content {
    padding-top: 100pt;
  }

  & .content {
    z-index: 0;
    display: block;
    padding-top: 40pt;
    width: 100%;
  }
`;
